import React, { Component } from 'react';
import io from 'socket.io-client';
import './App.css';

class App extends Component {
  constructor(props) {
    super(props);

    const storedTurnstiles = JSON.parse(localStorage.getItem('turnstiles'));
    const storedTotalPasses = JSON.parse(localStorage.getItem('totalPasses'));

    this.state = {
      turnstiles: storedTurnstiles || Array(20).fill('default'),
      totalPasses: storedTotalPasses || 0,
      flowRate: 0
    };

    this.socket = io('http://l33t.org.uk');
    this.socket.on('flash_turnstile', (data) => {
      const { turnstileNumber } = data;
      let { turnstiles, totalPasses } = this.state;
      turnstiles[turnstileNumber - 1] = 'green';
      totalPasses += 1;

      this.setState({ turnstiles, totalPasses });

      localStorage.setItem('turnstiles', JSON.stringify(turnstiles));
      localStorage.setItem('totalPasses', JSON.stringify(totalPasses));

      setTimeout(() => {
        turnstiles[turnstileNumber - 1] = 'default';
        this.setState({ turnstiles });
      }, 1000);
    });
  }

  resetData = () => {
    const defaultTurnstiles = Array(20).fill('default');

    this.setState({
      turnstiles: defaultTurnstiles,
      totalPasses: 0,
      flowRate: 0
    });

    localStorage.setItem('turnstiles', JSON.stringify(defaultTurnstiles));
    localStorage.setItem('totalPasses', JSON.stringify(0));
  }

  render() {
    return (
      <div className="App">
        {this.state.turnstiles.map((color, index) => (
          <div key={index} className={`turnstile ${color}`}>
            Turnstile {index + 1}
          </div>
        ))}
        <div className="data-display">
          Total Count: {this.state.totalPasses}
          <br />
          Flow Rate: {this.state.flowRate} per minute
        </div>
        <button className="reset-button" onClick={this.resetData}>Reset Data</button>
      </div>
    );
  }
}

export default App;
